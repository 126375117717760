
import { Options, Vue } from 'vue-class-component';
import { AppUserClient, InternalUserClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import * as OM from "@/Model";
import store from '@/store';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({})
export default class changePassword extends Vue {

    resetPasswordToken: string = "";
    newPassword: string = "";

    done: boolean = false;

    created() {
        this.resetPasswordToken = this.$route.params.resetPasswordToken as string;
    }

    concludePasswordReset() {
        AppUserClient.concludePasswordChange(this.resetPasswordToken, this.newPassword)
        .then(x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Fatto", "Fatto!"),
                subtitle: this.$localizationService.getLocalizedValue("La tua password è stata cambiata, torna nell'app per fare la login", "La tua password è stata cambiata, torna nell'app per fare la login"),
            })
            this.done = true;
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Errore", "Errore"),
                subtitle: y.Message,
                deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    get canSend() {
        return !!this.newPassword;
    }

}
